import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Page from './Page';
import reportWebVitals from './reportWebVitals';

let path = window.location.pathname.toLowerCase().trim();

let PATHS = [
  '/o-projektu',
  '/o-pcelama',
  '/zivot-u-kosnici',
  '/proizvodnja-meda',
  '/radim-li-kao-pcela',
  '/karijera-jedne-pcele',
  '/gdje-pcela-nauci-raditi-med',
  '/muskarci-trutovi-zene-kraljice',
  '/sto-kada-umre-kraljica-pcela',
  '/pcele-se-dresiraju',
  '/biljke-za-pcele',
  '/hotel-za-pcele',
  '/med-probiotik',
  '/smanjenje-stope-oprasivanja',
  '/pet-mocnih-recepata',
  '/kako-zuje-pcele-u-hrvatskoj',
  '/najkorisnije-cudoviste',
  '/gigabeetna-radionica',
  '/svi-radovi',
  '/gigabeetni-bonton',
];

let page = PATHS.indexOf(path);

if (page !== -1) {
  ReactDOM.render(
    <React.StrictMode>
      <Page page={page} />
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
